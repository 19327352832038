import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  Mutation,
  MutationAddOrganizationToCustomerArgs,
  MutationAddUserToOrganizationArgs,
  MutationBulkOrganizationUserRoleUpdateArgs,
  MutationBulkUserPermissionsUpdateArgs,
  MutationCreateOrganizationArgs,
  MutationRemoveUserFromOrganizationArgs,
  MutationUpdateOrganizationArgs,
  Query,
  QueryOrganizationArgs,
  QueryOrganizationsArgs,
  QueryOrganizationTreeByCustomerArgs,
  QueryOrganizationUsersArgs,
} from '../gen/models'
import { ORGANIZATION_USERS_QUERY, SimplifiedUserFragment, UserOrganizationFragment } from './users'

export const OrganizationFragment = gql`
  fragment OrganizationFragment on Organization {
    id
    parentId
    name
    address
    description
    code
    medicareId
    phoneNumber
    geoCode
    address2
    city
    state
    zipCode
    website
    facilityId
    createdAt
    updatedAt
    organizationType {
      id
      name
    }
    organizationTypeId
  }
`

const OrganizationTreeFieldsFragment = gql`
  fragment OrganizationTreeFieldsFragment on Organization {
    id
    name
  }
`

// Organizations Tree up to 4 levels
const OrganizationTreeFragment = gql`
  fragment OrganizationTreeFragment on Organization {
    ...OrganizationTreeFieldsFragment
    subOrganizations {
      ...OrganizationTreeFieldsFragment
      subOrganizations {
        ...OrganizationTreeFieldsFragment
        subOrganizations {
          ...OrganizationTreeFieldsFragment
        }
      }
    }
  }
  ${OrganizationTreeFieldsFragment}
`

const CREATE_ORGANIZATION = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragment}
`

export function useCreateOrganizationMutation() {
  return useMutation<Pick<Mutation, 'createOrganization'>, MutationCreateOrganizationArgs>(CREATE_ORGANIZATION, {
    refetchQueries: ['customer', 'organizationTreeByCustomer'],
  })
}

const ORGANIZATIONS_QUERY = gql`
  query organizations($input: GetOrganizationsByCriteriaInput!) {
    organizations(input: $input) {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragment}
`

export function useOrganizationsQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'organizations'>, QueryOrganizationsArgs>,
) {
  return useQuery(ORGANIZATIONS_QUERY, basicOptions)
}

const ORGANIZATION_QUERY = gql`
  query organization($input: IDArgs!) {
    organization(input: $input) {
      ...OrganizationFragment
      topUsers {
        ...SimplifiedUserFragment
      }
      topPowerUsers {
        ...SimplifiedUserFragment
      }
      totalUsers
      subOrganizations {
        topPowerUsers {
          ...SimplifiedUserFragment
        }
        totalSubOrganizations
        ...OrganizationFragment
      }
      allParents {
        id
        name
      }
    }
  }
  ${OrganizationFragment}
  ${SimplifiedUserFragment}
`

export function useOrganizationQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'organization'>, QueryOrganizationArgs>,
) {
  return useQuery(ORGANIZATION_QUERY, basicOptions)
}

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragment}
`

export function useUpdateOrganizationMutation() {
  return useMutation<Pick<Mutation, 'updateOrganization'>, MutationUpdateOrganizationArgs>(UPDATE_ORGANIZATION, {
    refetchQueries: ['customer', 'organizationTreeByCustomer'],
  })
}

const ADD_ORGANIZATION_TO_CUSTOMER = gql`
  mutation addOrganizationToCustomer($input: AddOrganizationToCustomerInput!) {
    addOrganizationToCustomer(input: $input) {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragment}
`

export function useAddOrganizationToCustomer() {
  return useMutation<Pick<Mutation, 'addOrganizationToCustomer'>, MutationAddOrganizationToCustomerArgs>(
    ADD_ORGANIZATION_TO_CUSTOMER,
    {
      refetchQueries: ['customer', 'organizationTreeByCustomer'],
    },
  )
}

const ADD_USER_TO_ORGANIZATION = gql`
  mutation addUserToOrganization($input: UserOrganizationInput!) {
    addUserToOrganization(input: $input) {
      ...UserOrganizationFragment
    }
  }
  ${UserOrganizationFragment}
`

export function useAddUserToOrganizationMutation(updateQueryVariables?: QueryOrganizationUsersArgs) {
  return useMutation<Pick<Mutation, 'addUserToOrganization'>, MutationAddUserToOrganizationArgs>(
    ADD_USER_TO_ORGANIZATION,
    {
      update: (proxy, response) => {
        const data = proxy.readQuery<Pick<Query, 'organizationUsers'>>({
          query: ORGANIZATION_USERS_QUERY,
          variables: updateQueryVariables,
        })
        const cachedOrganizationUsers = data && data.organizationUsers
        const newOrganizationUser = response.data && response.data.addUserToOrganization
        if (cachedOrganizationUsers !== null && newOrganizationUser) {
          proxy.writeQuery<Pick<Query, 'organizationUsers'>, QueryOrganizationUsersArgs>({
            query: ORGANIZATION_USERS_QUERY,
            variables: updateQueryVariables,
            data: { organizationUsers: cachedOrganizationUsers.concat(newOrganizationUser) },
          })
        }
      },
    },
  )
}

const REMOVE_USER_FROM_ORGANIZATION = gql`
  mutation removeUserFromOrganization($input: RemoveOrganizationInput!) {
    removeUserFromOrganization(input: $input) {
      ...UserOrganizationFragment
    }
  }
  ${UserOrganizationFragment}
`

export function useRemoveUserFromOrganizationMutation(updateQueryVariables?: QueryOrganizationUsersArgs) {
  return useMutation<Pick<Mutation, 'removeUserFromOrganization'>, MutationRemoveUserFromOrganizationArgs>(
    REMOVE_USER_FROM_ORGANIZATION,
    {
      update: (proxy, response) => {
        const data = proxy.readQuery<Pick<Query, 'organizationUsers'>>({
          query: ORGANIZATION_USERS_QUERY,
          variables: updateQueryVariables,
        })
        const cachedOrganizationUsers = data && data.organizationUsers
        const removedOrganizationUser = response.data && response.data.removeUserFromOrganization
        if (cachedOrganizationUsers !== null && removedOrganizationUser) {
          proxy.writeQuery<Pick<Query, 'organizationUsers'>>({
            query: ORGANIZATION_USERS_QUERY,
            variables: updateQueryVariables,
            data: {
              organizationUsers: cachedOrganizationUsers.filter(
                user => user.user.id !== removedOrganizationUser.usersId,
              ),
            },
          })
        }
      },
    },
  )
}

const BULK_UPDATE_USER_PERMISSIONS = gql`
  mutation bulkUserPermissionsUpdate($input: BulkUserPermissionUpdateInput!) {
    bulkUserPermissionsUpdate(input: $input) {
      ...UserOrganizationFragment
    }
  }
  ${UserOrganizationFragment}
`

export function useBulkUserPermissionsUpdateMutation() {
  return useMutation<Pick<Mutation, 'bulkUserPermissionsUpdate'>, MutationBulkUserPermissionsUpdateArgs>(
    BULK_UPDATE_USER_PERMISSIONS,
  )
}

const BULK_UPDATE_ORGANIZATION_USERS_ROLE = gql`
  mutation bulkOrganizationUserRoleUpdate($input: BulkUserOrganizationRoleUpdateInput!) {
    bulkOrganizationUserRoleUpdate(input: $input) {
      ...UserOrganizationFragment
    }
  }
  ${UserOrganizationFragment}
`

export function useBulkOrganizationUserRoleUpdateMutation(updateQueryVariables?: QueryOrganizationUsersArgs) {
  return useMutation<Pick<Mutation, 'bulkOrganizationUserRoleUpdate'>, MutationBulkOrganizationUserRoleUpdateArgs>(
    BULK_UPDATE_ORGANIZATION_USERS_ROLE,
    {
      update: (proxy, response) => {
        const data = proxy.readQuery<Pick<Query, 'organizationUsers'>>({
          query: ORGANIZATION_USERS_QUERY,
          variables: updateQueryVariables,
        })
        const cachedOrganizationUsers = data && data.organizationUsers
        const updatedOrganizationUsers = response.data && response.data.bulkOrganizationUserRoleUpdate
        if (cachedOrganizationUsers !== null && updatedOrganizationUsers) {
          proxy.writeQuery<Pick<Query, 'organizationUsers'>>({
            query: ORGANIZATION_USERS_QUERY,
            variables: updateQueryVariables,
            data: {
              organizationUsers: cachedOrganizationUsers.map(organizationUser => {
                const found = updatedOrganizationUsers.find(uo => uo.usersId === organizationUser.usersId)
                return {
                  ...organizationUser,
                  isPowerUser: found ? found.isPowerUser : organizationUser.isPowerUser,
                }
              }),
            },
          })
        }
      },
    },
  )
}

const ORGANIZATION_TREE_QUERY = gql`
  query organizationTreeByCustomer($input: IDArgs!) {
    organizationTreeByCustomer(input: $input) {
      ...OrganizationTreeFragment
    }
  }
  ${OrganizationTreeFragment}
`

export function useOrganizationTreeQuery(
  baseOptions?: QueryHookOptions<Pick<Query, 'organizationTreeByCustomer'>, QueryOrganizationTreeByCustomerArgs>,
) {
  return useQuery(ORGANIZATION_TREE_QUERY, baseOptions)
}
