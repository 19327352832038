import React from 'react'
import styled from 'styled-components'
import { Contract, ContractStatusEnum } from '../../../api'

export const ContractStatusTag: React.FC<{ contract: Contract }> = ({ contract }) => {
  const status = getContractStatus(contract)
  return (
    <Wrapper
      bg={
        status === 'expired'
          ? '#767D89'
          : status === 'expiringSoon'
          ? '#FF0000'
          : status === ContractStatusEnum.Draft
          ? '#9299A6'
          : '#00cc99'
      }>
      <StatusSpan>{CONTRACT_STATUS_LABELS_MAP[status]}</StatusSpan>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ bg: string }>`
  background: ${({ bg }) => bg};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  height: 16px;
  text-align: center;
`

const StatusSpan = styled.span`
  color: #fff;
  text-transform: uppercase;
  padding: 0 2px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.05em;
`

const CONTRACT_STATUS_LABELS_MAP = {
  [ContractStatusEnum.Draft]: 'Draft',
  [ContractStatusEnum.Active]: 'Active',
  [ContractStatusEnum.CostAvoidance]: 'Cost Avoidance',
  [ContractStatusEnum.Declined]: 'Declined',
  [ContractStatusEnum.NoLongerActive]: 'No Longer Active',
  [ContractStatusEnum.Pending]: 'Pending',
  expired: 'Expired',
  expiringSoon: 'Expiring Soon',
}

const getContractStatus = (contract: Contract): ContractStatusEnum | 'expired' | 'expiringSoon' => {
  const expiredTime = new Date(contract.activeDue)
  const daysToExpired = (expiredTime.getTime() - new Date().getTime()) / 86400000
  if (contract.status === ContractStatusEnum.Active) {
    if (daysToExpired < 0) {
      return 'expired'
    }
    if (daysToExpired < 90) {
      return 'expiringSoon'
    }
  }
  return contract.status!
}
