import { AppState, Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react'
import React, { useCallback, useEffect } from 'react'
import { Outlet, Routes, useLocation, useNavigate } from 'react-router'
import { Route, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import './App.less'
// @ts-ignore
import Env from './api/gen/Env'
import { Main } from './routes/Main'
import ApertureOnboarding from './routes/ProjectAperture/Onboarding/Onboarding'
import { CategoryStakeholderResponse } from './routes/SourcingPlan/CategoryStakeholderSurvey'
import { UserUpdateNewEmailConfirmation } from './routes/Users/UserUpdateNewEmailConfirmation'
import * as dt from './ui/design-tokens/tokens'

function usePageViews() {
  const location = useLocation()
  useEffect(() => {
    const rawWindow: any = window
    const _hsq = (rawWindow._hsq = rawWindow._hsq || [])
    if (Env.useHubspot) {
      _hsq.push(['setPath', location.pathname])
      _hsq.push(['trackPageView'])
    } else {
      _hsq.push(['doNotTrack'])
    }
  }, [location])
}

const SecuredMain = withAuthenticationRequired(Main)

function AppProviders() {
  usePageViews()

  const navigate = useNavigate()
  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      // Use the router's history module to replace the url
      navigate(appState?.returnTo || window.location.pathname, { state: { onLogin: true }, replace: true })
    },
    [navigate],
  )

  return (
    <Auth0Provider
      domain={Env.authDomain}
      clientId={Env.authClientId}
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: Env.authAudience,
        scope: 'openid profile email offline_access',
      }}
      cacheLocation="localstorage"
      legacySameSiteCookie={false}
      useRefreshTokensFallback={true}
      onRedirectCallback={onRedirectCallback}>
      <ThemeProvider theme={dt}>
        <Outlet />
      </ThemeProvider>
    </Auth0Provider>
  )
}

function RouterRoot() {
  return (
    <Routes>
      <Route path="/non-login/sourcing-plan/stakeholder-survey" element={<CategoryStakeholderResponse />} />
      <Route path="/non-login/category/stakeholder-survey" element={<CategoryStakeholderResponse />} />
      <Route path="/non-login/user/update-new-email" element={<UserUpdateNewEmailConfirmation />} />
      <Route path="/non-login/aperture/onboarding/*" element={<ApertureOnboarding />} />
      <Route element={<AppProviders />}>
        <Route path="/*" element={<SecuredMain />} />
      </Route>
    </Routes>
  )
}

const router = createBrowserRouter([{ path: '*', Component: RouterRoot }])

const App: React.FC<{}> = () => {
  return <RouterProvider router={router} />
}

export default App
