import { Button, Divider, Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { LabeledValue } from 'antd/lib/tree-select'
import React, { RefObject, useMemo } from 'react'
import { Organization, useOrganizationsQuery } from '../../../api'

const { Option } = Select

type Props = SelectProps<LabeledValue | LabeledValue[]> & {
  onChangeFull?: (selected?: Organization | Organization[] | null) => void
  onSelectFull?: (selected: Organization) => void
  facilityIdAsKey?: boolean
  customRef?: RefObject<any> | null
  allowSelectAll?: boolean
  skipValues?: string[]
}

export const OrganizationsSelector: React.FC<Props> = ({
  searchValue,
  onChangeFull,
  onChange,
  onSelect,
  onSelectFull,
  facilityIdAsKey,
  customRef,
  allowSelectAll,
  skipValues,
  ...props
}) => {
  const { data, loading } = useOrganizationsQuery({ variables: { input: { searchText: searchValue } } })
  const organizations = useMemo(
    () =>
      (data &&
        data.organizations &&
        data.organizations.filter(
          organization =>
            !!organization.facilityId &&
            !(skipValues || []).includes(facilityIdAsKey ? organization.facilityId.toString() : organization.id),
        )) ||
      [],
    [data, skipValues, facilityIdAsKey],
  )

  const handleSelectAll = () => {
    if (props.mode !== 'multiple') {
      return
    }
    if (onChangeFull) {
      onChangeFull(organizations)
      return
    }
    if (onChange) {
      const options = organizations.map(o => ({
        label: o.name,
        value: facilityIdAsKey ? o.facilityId!.toString() : o.id,
      }))
      onChange(options, options)
    }
  }

  return (
    <Select
      {...props}
      ref={customRef}
      showAction={['focus', 'click']}
      onChange={(...params) => {
        if (onChangeFull) {
          const selected =
            params[0] === null
              ? null
              : params[0] === undefined
              ? undefined
              : Array.isArray(params[0])
              ? (params[0] as LabeledValue[]).map(s => organizations.find(org => org.id === s.value)!)
              : organizations.find(org => org.id === (params[0] as LabeledValue).value)
          onChangeFull(selected)
          return
        }
        if (onChange) {
          onChange(...params)
        }
      }}
      onSelect={(a: any, v: any) => {
        if (onSelectFull) {
          onSelectFull(organizations.find(org => org.id === a.value)!)
          return
        }
        if (onSelect) {
          onSelect(a, v)
        }
      }}
      loading={loading}
      labelInValue
      showSearch
      optionFilterProp="children"
      showArrow
      // eslint-disable-next-line react/no-unstable-nested-components
      dropdownRender={menu => (
        <>
          {allowSelectAll && props.mode === 'multiple' && (
            <>
              <Button type="text" block onClick={handleSelectAll}>
                Select All
              </Button>
              <Divider style={{ margin: '8px 0' }} />
            </>
          )}
          {menu}
        </>
      )}>
      {organizations.map(organization => (
        <Option
          title={organization.name}
          key={organization.id}
          value={facilityIdAsKey ? organization.facilityId!.toString() : organization.id}>
          {organization.name}
        </Option>
      ))}
    </Select>
  )
}
