import { Space, Statistic } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Contract, ContractReportStatusEnum, ContractStatusEnum } from '../../../api'
import { ContractStepsEnum, getStepIndex } from '../../Contracts/ContractCreate/utils'
import { ContractStatusTag } from '../../Contracts/components/ContractStatusTag'

const ContractDateFormat = new Intl.DateTimeFormat('en', { month: 'short', day: '2-digit', year: 'numeric' })

export const ContractCard: React.FC<{ contract: Contract }> = ({ contract }) => {
  return (
    <ContractListItemCard>
      <ContractTitleWrapper>
        <ContractTitle
          to={
            contract.status === ContractStatusEnum.Draft
              ? `/analytics/contracts/create/${contract.id}`
              : contract.reportStatus === ContractReportStatusEnum.NotAvailable
              ? `/analytics/contracts/${contract.id}/update?step=${getStepIndex(ContractStepsEnum.summary)}`
              : `/analytics/contracts/${contract.id}`
          }>
          {contract.name}
        </ContractTitle>
        <Space>
          <ContractStatusTag contract={contract} />
        </Space>
      </ContractTitleWrapper>
      <ContractInfoWrapper>
        <Statistic title="Contract #" formatter={false} value={contract.code} />
        <Statistic title="Supplier" value={contract.supplier} />
        <Statistic title="Pricing Tiers" value={contract.numTiers || ''} />
        <Statistic title="Expiration Date" value={ContractDateFormat.format(new Date(contract.activeDue))} />
      </ContractInfoWrapper>
    </ContractListItemCard>
  )
}

const ContractInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const ContractListItemCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 32px 20px 32px;
  background: #fff;
  margin-bottom: 8px;
  border-radius: 4px;
`

const ContractTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 8px;
  text-wrap: nowrap;
`

const ContractTitle = styled(Link)`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 32px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #3b7cff;
`
