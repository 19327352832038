import { isNil } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { FireGradientSVG } from 'src/ui/svgs'
import { Basket } from '../../../api'
import * as dt from '../../../ui/design-tokens/tokens'
import { formatCurrency } from '../../../utils'

const formatBasketCurrency = (amount: number | undefined) => {
  if (amount === null || amount === undefined) {
    return '-'
  }
  const _1Mil = 1000000
  if (amount > _1Mil) {
    return `$${Math.round((amount * 100) / _1Mil) / 100}M`
  }
  return formatCurrency(amount, 0)
}

const calculateLikelihood = (priceScoreLikelihoods: number[] = [], targetPercentile: number) => {
  // Percentile index is 1 less than percentile number
  const result = priceScoreLikelihoods[targetPercentile - 1]
    ? Math.round(priceScoreLikelihoods[targetPercentile - 1] * 100)
    : null
  if (result && result > 99) {
    return 99
  }
  return result
}

const calculateOpportunity = (basket: Basket, targetPercentile: number) => {
  if (basket.insights.projectedSpend && basket.insights.percentiles) {
    return basket.insights.projectedSpend - basket.insights.percentiles[100 - targetPercentile]
  }
  return undefined
}

const getLikelihoodDisplay = (likelihood?: number | null): { color: string; label: string } => {
  if (isNil(likelihood)) {
    return { color: dt.ColorNeutral90, label: '-' }
  }
  if (likelihood >= 80) {
    return { color: dt.ColorGreen70, label: 'High' }
  }
  if (likelihood < 50) {
    return { color: dt.ColorRed50, label: 'Low' }
  }
  return { color: dt.ColorNeutral90, label: 'Medium' }
}

type Props = { basket: Basket; targetPercentile: number }

export const BasketListItemStatistics: React.FC<Props> = ({ basket, targetPercentile }) => {
  const opportunity = calculateOpportunity(basket, targetPercentile)
  const likelihood = calculateLikelihood(basket.likelihoods?.priceScoreLikelihoods, targetPercentile)
  const totalSpend = basket.insights.totalSpend
  const likelihoodDisplay = getLikelihoodDisplay(likelihood)
  return (
    <StatisticsWrapper>
      <Statistic>
        <div className="value" style={{ color: dt.ColorNeutral90 }}>
          {formatBasketCurrency(opportunity)}
        </div>
        <div className="label">Opportunity</div>
      </Statistic>
      {opportunity && opportunity > 0 && (
        <Statistic>
          <div
            className="value"
            style={{
              color: likelihoodDisplay.color,
            }}>
            {likelihoodDisplay.label}
            {likelihoodDisplay.label === 'High' ? <FireGradientSVG /> : null}
          </div>
          <div className="label">Likelihood</div>
        </Statistic>
      )}
      <Statistic>
        <div className="value">{formatBasketCurrency(totalSpend)}</div>
        <div className="label" title="Rolling 12M Spend">
          12M Spend
        </div>
      </Statistic>
    </StatisticsWrapper>
  )
}

const StatisticsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Statistic = styled.div`
  &:last-child {
    text-align: right;
    .value {
      justify-content: flex-end;
    }
  }
  .label {
    color: ${dt.ColorNeutral70};
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.03rem;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .value {
    color: ${dt.ColorNeutral90};
    font-size: ${dt.TextDisplaySmallFontSize};
    font-weight: ${dt.TextDisplaySmallFontWeight};

    display: flex;
    align-items: center;
    gap: 2px;
  }
`
