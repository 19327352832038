export default {
  api: 'https://api.pre-prod.cx.curvo.cloud/graphql',
  agGridLicense:
    '',
  supplierPortalBaseUrl: 'https://activate.curvolabs.com',
  authDomain: 'curvo.us.auth0.com',
  authClientId: 'Yuymo7QivJOJqhPxC41yna0cu3Ck4BQx',
  authAudience: 'https://curvo.us.auth0.com/api/v2/',
  useHubspot: false,
  useHeap: false,
  launchDarklyClientId:
    '642a8c28bdd73812d6f1d334',
}
