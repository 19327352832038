import { QueryResult } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { message } from 'antd'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { CustomerTypeEnum, User, UserContext } from '../gen/models'
import { useMeQuery, useOnLoginMutation } from '../hooks/users'
// @ts-ignore
import Env from '../gen/Env'
import { PageLoading } from '../../common/components/PageLoading'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & { children?: React.ReactNode }

export type MeConsumerData = UserContext

type MeConsumerResponse = {
  me: MeConsumerData
}

type MeContextType = Partial<QueryResult<MeConsumerResponse>> & {
  refetchData?(): void
}

type MeWithoutPaymentContextType = Partial<QueryResult<User>> & {
  refetchData?(): void
}

export const MeContext = React.createContext<MeContextType>({})

export const MeWithoutPaymentContext = React.createContext<MeWithoutPaymentContextType>({})

const MeWithoutPaymentConsumer = MeWithoutPaymentContext.Consumer

const MeProvider: React.FC<Props> = ({ children }) => {
  const { data, loading, error } = useMeQuery()
  const { logout } = useAuth0()
  const location = useLocation()
  const [onLogin] = useOnLoginMutation()

  const me = data?.me
  const onLoginState = location.state?.onLogin

  useEffect(() => {
    if (onLoginState && !loading && me) {
      onLogin().then(() => {})
    }
  }, [onLogin, loading, me, onLoginState])

  useEffect(() => {
    if (error) {
      message.error('Unexpected Error, Please login again')
      logout()
    }
  }, [error, logout])

  if (data?.me.user.customer.type === CustomerTypeEnum.Supplier) {
    window.location.href = Env.supplierPortalBaseUrl
    return <></>
  }

  return (
    <MeContext.Provider value={{ data, loading, error }}>{loading ? <PageLoading /> : children}</MeContext.Provider>
  )
}

export { MeProvider, MeWithoutPaymentConsumer }
