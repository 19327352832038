import { findIndex } from 'lodash'

export enum ContractStepsEnum {
  basic = 'basic',
  tiers = 'tiers',
  productAndPricing = 'productAndPricing',
  crossReference = 'crossReference',
  documents = 'documents',
  members = 'members',
  summary = 'summary',
}

export const STEP_CONFIGS: { name: string; step: ContractStepsEnum; disabled?: boolean; required?: boolean }[] = [
  { name: 'Basic Info', step: ContractStepsEnum.basic, required: true },
  { name: 'Tiers', step: ContractStepsEnum.tiers, required: true },
  { name: 'Products And Pricing', step: ContractStepsEnum.productAndPricing, required: true },
  { name: 'Cross References', step: ContractStepsEnum.crossReference },
  { name: 'Supporting Documents', step: ContractStepsEnum.documents },
  { name: 'Manage Members', step: ContractStepsEnum.members },
  { name: 'Summary', step: ContractStepsEnum.summary },
]

export const getStepIndex = (step: ContractStepsEnum) => {
  return findIndex(STEP_CONFIGS, s => s.step === step)
}

export const getLayoutWidth = (step: ContractStepsEnum) => {
  switch (step) {
    case ContractStepsEnum.basic:
      return 532
    case ContractStepsEnum.members:
    case ContractStepsEnum.crossReference:
    case ContractStepsEnum.productAndPricing:
      return 'calc(100% - 6rem)'
    case ContractStepsEnum.summary:
      return 800
    default:
      return 1200
  }
}
